import { graphql } from "gatsby"
import React,{useEffect, useState} from "react"
import CareerBanner from "../components/CareerBanner/CareerBanner"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LandingContent from "../components/LandingContent/LandingContent"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import CareerOpportunities from "../components/CareerOpportunities/CareerOpportunities"
import JobOppenings from "../components/JobOppenings/JobOppenings"
import CareerSlider from "../components/CareerSlider/CareerSlider"
import useDeviceMedia from "../hooks/useDeviceMedia"
import LandingBanner from "../components/LandingBanner/LandingBanner"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"

const OurStoryLanding = ({ data, pageContext }) => {
  // const pageData = data?.strapiPage
  const [pageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)
  const careerList=data?.allStrapiCareer?.edges?.length>0?data?.allStrapiCareer?.edges:""

  const {isMobile}=useDeviceMedia()

  let popUpData=""

  if(pageData?.add_page_modules?.length>0){
     const results=pageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        seo: "*",
        banner:{
          populate:{
            image:"*",
            cta_1_link:{
              populate:{
                parent:"*"
              }
            },
            cta_2_link:{
              populate:{
                parent:"*"
              }
            },
            cta_3_link:{
              populate:{
                parent:"*"
              }
            }
          }
        },
        add_page_modules: {
          on: {
            'page-modules.image-content': {
              populate: {
                left_right_block: {
                  populate:{
                  image:"*",
                  menu:{
                    populate:{
                      parent:"*"
                    }
                  }
                }
              }
              },
            },
            "components.title-desc":{
              populate:"*"
            },
            
            "page-modules.primary-module":{
              populate:{
                items:{
                  populate:{
                    image:"*",
                      cta:{
                        link:{
                          populate:{
                            parent:"*"
                          }
                        }
                      }
                      
                  }
                }
              }
            },
            "page-modules.valuation-image":{
              populate:{
                add_image:{
                  populate:{
                    images: "*"
                  }
                }
              }
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.banner.banner_image = {}
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          myPrevData.banner = previewData?.banner
          myPrevData.banner.image = previewData?.banner?.image
          myPrevData.banner.banner_content = previewData?.banner?.banner_content
          myPrevData.banner.banner_title = previewData?.banner?.banner_title
          myPrevData.banner.cta_1_title = previewData?.banner?.cta_1_title
          myPrevData.banner.cta_1_custom_link = previewData?.banner?.cta_1_custom_link
          myPrevData.banner.cta_1_link = previewData?.banner?.cta_1_link
          myPrevData.banner.cta_2_link = previewData?.banner?.cta_2_link
          myPrevData.banner.cta_2_title = previewData.banner.cta_2_title
          myPrevData.banner.cta_2_custom_link = previewData?.banner?.cta_2_custom_link
          myPrevData.banner.cta_3_custom_link = previewData?.banner?.cta_3_custom_link
          myPrevData.banner.cta_3_title = previewData?.banner?.cta_3_title
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.pdf = previewData?.pdf
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.slug = previewData?.slug
          myPrevData.mobile_image = previewData?.mobile_image
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(pageData => ({ ...pageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(enablePreview){
    return null
  }

  return (
    <Layout popUpData={popUpData} popularSearch={pageData?.select_popular_search?.title}>
     
      {/* <CareerBanner pageData={pageData} /> */}
      <LandingBanner isPreviewEnabled={isPreviewEnabled} pageData={pageData} />

      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
             item?.__component === "components.title-desc")
            && (
              <LandingContent isPreviewEnabled={isPreviewEnabled} data={item} />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" ||
              item?.__component ==="page-modules.image-content")
              && (
              <ImageContentComponent
                data={item}
                pageData={pageData}
                career={true}
                isPreviewEnabled={isPreviewEnabled}
              />
            )}

            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" ||
            item?.__component ==="page-modules.valuation-image")
            && isMobile&& item.count==="1"&&(
              <CareerSlider data={item} pageData={pageData} />
            )}

            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" ||
            item?.__component ==="page-modules.valuation-image")
            && !isMobile&& (
              <CareerSlider data={item} pageData={pageData} count={item.count}/>
            )}
            


            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE" ||
              item?.__component === "page-modules.primary-module")
              &&
              item.title !== "Current Vacancies" && (
                <CareerOpportunities isPreviewEnabled={isPreviewEnabled} data={item} pageData={pageData} />
              )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE" ||
              item?.__component === "page-modules.primary-module")
              &&
              item.title === "Current Vacancies" && (
                <JobOppenings isPreviewEnabled={isPreviewEnabled} data={item} pageData={pageData} careerList={careerList}/>
              )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  let myimgtransforms = ""
  if (PageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      PageData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (PageData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(PageData?.imagetransforms?.metaog_Transforms)
  }


  let getImg= PageData?.banner?.image?.url? PageData?.banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  let imageUrl=""

  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }
  else if(myimgtransforms){
    // ogRender()
    const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    imageUrl=ProcessedResult?ProcessedResult["768x397"]:PageData?.banner?.image?.url
  }


//new ggfx image
const getImage =
PageData?.banner?.image?.url
    ? PageData?.banner?.image?.url.replace(
        "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
        "",
      )
    : ""
const filterImage =
  getImage && PageData?.ggfx_results?.length > 0
    ? PageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
    : ""

// filter image with image size
const getOGImg =
  filterImage?.length > 0 && filterImage[0].transforms?.length > 0
    ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
    : ""

 //to get image in uploaded format   
const ogRenderNew = ()=>{
  GetTransformedUrl({
    srcCftle: findImage,
    format: ext,
    transform:"600x400",
    id: PageData.strapi_id,
    field: "metaog",
    contentType: "page"
  })
}

useEffect(()=>{
  if(!getOGImg?.length>0)
      ogRenderNew()
},[]) 

//----


//preview seo dynamic updates

useEffect(() => {
  const timer = setTimeout(() => {
    if (typeof window !== "undefined" && isPreview) {
      try {
        const sessionMetaData = sessionStorage.getItem("previewMeta");
        if (sessionMetaData) {
          const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, [isPreview]);

useEffect(() => {
  if(isPreview){
  document.title =  `${preMetaTitle} haus & haus`;
  }
}, [preMetaTitle]);
//


const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl
  

  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} 
      description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl={pageImage}
      />
  )
}

export default OurStoryLanding

export const query = graphql`
  query ($page_id: String) {
    allStrapiCareer(filter: {publish: {eq: true}}) {
      edges {
        node {
          location
          title
          strapi_id
          slug
          image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          imagetransforms {
            image_Transforms
          }
        }
      }
    }

    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          id
          __typename
          count
          add_image {
            images {
              url
              alternativeText
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
          items {
            title
            sub_title
            video_url
            image {
              url
              alternativeText
            }
            cta{
              link{
                slug
              }
              custom_link
              link_type
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          id
          left_right_block {
            image {
              url
              alternativeText
            }
            cta_label
            menu{
              slug
              strapi_parent {
                slug
              }
            }
            custom_link
            title
            description {
              data {
                description
              }
            }
            video_url
            layout
          }
        }
      }
    }
  }
`
